<template>
    <div></div>
</template>
<script>
export default {
  data(){
    return{
      bizId:'',
      sceneNo:''
    }
  },
  watch:{

  },
  components: {
   
  },
   mounted() {
      // var url = '/api/scm/wechatPay/getAuthUrl?redirectUri='+"/h/scm/wxVirtual";
      //   this.request.post(url,{redirectUri:'/h/scm/wxVirtual'}).then(res =>{
      //     console.log(res)
      //   })
     this.getOpenId();
   },
  methods:{
    getOpenId(){
      const {code,state} = this.$route.query;
      console.log('授权回调页面')
      console.log(code)
      console.log(state)
      this.request.post('/api/scm/wechatPay/getOpenId',{code:code,state:state}).then(res =>{
        // 
        this.orderPay(res);
      })
    },
    orderPay(openid){
      console.log('取得用户openid'+openid)
      console.log(openid)
      this.bizId = sessionStorage.getItem('orderBizId');
      this.sceneNo = sessionStorage.getItem('sceneNo');
      var  payBizId = this.bizId + String(new Date().getTime()).substr(-4, 4);
      var payType = 5;
      var  url = `${window.location.origin}/h/scm/order/payCheck?bizId=${this.bizId}&payType=${payType}&sceneNo=${this.sceneNo}&virtual=1`,payBizId = payBizId;
      this.request
        .postFrom(
          `/api/scm/pay/order?bizId=${this.bizId}&virtual=1&payType=${payType}&payBizId=${payBizId}&openid=${openid}&returnUrl=${encodeURIComponent(url)}`,
          {}
        )
        .then((res) => {
          if (!!res && !!res.paymentToken) {
            if (payType == 5 ||payType == 7 || payType == 12 || payType == 22) {
              sessionStorage.setItem(`payed${this.bizId}`, true);
              //  支付宝和微信跳转链接
              window.location.href = res.paymentToken;
            } else {
              this.$router.replace({
                path: "/h/scm/order/payCheck",
                query: {
                  bizId: this.bizId,
                  payType: 5,
                  productId: this.$route.query.productId,
                  virtual:true,
                  sceneNo:this.sceneNo
                }
              });
            }
          } else {
            if (!!res)
              this.$toast({
                message: "支付失败，请稍候重试",
                position: "bottom"
              });
          }
        });
    }

  }
}
</script>
<style lang="scss">

</style>
